@layer reset, base, tokens, recipes, utilities;

/* Prevent Drawer used in mobile nav bar from blocking click events on Termly dialog */
body:has([aria-label="Cookie Consent Prompt"]) {
  pointer-events: auto !important;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}
